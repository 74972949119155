import React from "react";

import { MdOutlineSupportAgent } from "react-icons/md";
import MainDashboard from "views/admin/default";
import CreditTransactions from "views/admin/creditTransactions";
import DebitTransactions from "views/admin/debitTransactions";
import LedgerPassbook from "views/admin/ledgerPassbook";
import Creds from "views/admin/creds";
import Docs from "views/admin/docs";

const routes = [
  {
    name: "Home",
    layout: "/admin",
    path: "default",
    component: <MainDashboard />,
  },
  {
    name: "Funds Loaded",
    layout: "/admin",
    path: "creditTransactions",
    icon: <MdOutlineSupportAgent />,
    component: <CreditTransactions />,
  },
  {
    name: "Payout",
    layout: "/admin",
    path: "debitTransactions",
    icon: <MdOutlineSupportAgent />,
    component: <DebitTransactions />,
  },
  {
    name: "Ledger Passbook",
    layout: "/admin",
    path: "ledgerPassbook",
    icon: <MdOutlineSupportAgent />,
    component: <LedgerPassbook />,
  },
  // {
  //   name: "Bulk Payout",
  //   layout: "/admin",
  //   path: "bulkPayout",
  //   icon: <MdOutlineSupportAgent />,
  //   component: <BulkPayout />,
  // },
  {
    name: "API Creds",
    layout: "/admin",
    path: "creds",
    icon: <MdOutlineSupportAgent />,
    component: <Creds />,
  },
  {
    name: "API Docs",
    layout: "/admin",
    path: "docs",
    icon: <MdOutlineSupportAgent />,
    component: <Docs />,
  },
];
export default routes;
