import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import SignIn from "views/auth/SignIn";
import { useEffect } from "react";
import { useCookies } from "react-cookie";

export default function Auth() {
  const [cookies, setCookie] = useCookies(["merchant_access_token"]);
  useEffect(() => {
    if (
      cookies.merchant_access_token &&
      cookies.merchant_access_token != "undefined"
    ) {
      
      window.location.replace("/admin");
    }
  }, [cookies]);

  document.documentElement.dir = "ltr";

  if (
    cookies.merchant_access_token &&
    cookies.merchant_access_token != "undefined"
  ) {
    return <h1>Redirecting...</h1>;
  }

  return (
    <div>
      <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
        <FixedPlugin />
        <main className={`mx-auto min-h-screen`}>
          <div className="relative flex">
            <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-[8rem] lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
              <div className="mb-auto flex flex-col pl-5 pr-5 md:pr-0  lg:max-w-[100%] lg:pl-0 xl:max-w-full">
                <SignIn />
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}


