import axios from "axios";
import { BACKEND_URL } from "./config.js";

const instance = axios.create({
  baseURL: BACKEND_URL,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("error", error.response);
    if (error.response && (error.response.status === 401 || error.response.status === 412)) {
      document.cookie = "merchant_access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
    return Promise.reject(error);
  }
);

instance.interceptors.request.use(
  (config) => {
    const token = document.cookie.match(/merchant_access_token=([^;]+)/)[1];
    console.log("okkkkkk", token);
    if (token && token != "undefined") {

      config.headers.Authorization = `Bearer ${token}`;
    }
    // document.cookie = "merchant_access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
