const { default: instance } = require("utils/axios");

const getDebitTransactions = async () => {
  const response = await instance.get("/merchant/get-debitList");
  return response.data;
};

const getCreditTransactions = async () => {
  const response = await instance.get("/merchant/get-creditList");
  return response.data;
};

const getDashboardData = async () => {
  const response = await instance.get("/merchant/getDashboardData");
  return response.data;
};


const getLedgerReport = async () => {
  const response = await instance.get("/merchant/get-ledger");
  return response.data;
}

const fetchVA = async () => {
  const response = await instance.get("/merchant/credentials");
  return response.data;
}


const updateCallback= async (data) => {
  const response = await instance.post("/merchant/update-callback",data);
  return response.data;
}


const getOtp = async () => {
  const response = await instance.get("merchant/sendOtp");
  return response.data;

}
const verifyOtp = async (data) => {
  console.log(data , "test")
  const response = await instance.post("merchant/verifyOtp",data);

  return response.data;

}
const uploadFile = async (data) => {
  const response = await instance.post("merchant/bulk-payout",data);
  return response.data;

}


const getBulkDebitTransactions = async () => {
  const response = await instance.get("/merchant/get-bulkDebitList");
  return response.data;
};






export { getDebitTransactions,getOtp ,getBulkDebitTransactions, verifyOtp , uploadFile, getCreditTransactions, getDashboardData,getLedgerReport,fetchVA,updateCallback };
