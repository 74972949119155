import React, { useState, useCallback } from "react";

function CopyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="h-5 w-5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
      />
    </svg>
  );
}

function CopyButton({ text }) {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  }, [text]);

  return (
    <button
      onClick={copyToClipboard}
      className="ml-2 rounded p-1 text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
    >
      {copied ? (
        <span className="text-sm text-green-500">Copied!</span>
      ) : (
        <CopyIcon />
      )}
    </button>
  );
}

export default function Docs() {
  const [activeTab, setActiveTab] = useState("initiate-payout");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const renderCodeBlock = (code, language = "json") => (
    <div className="relative">
      <pre className="overflow-x-auto rounded-md bg-gray-100 p-4">
        <code className={`language-${language}`}>{code}</code>
      </pre>
      <div className="absolute top-2 right-2">
        <CopyButton text={code} />
      </div>
    </div>
  );

  return (
    <div className="container mx-auto p-4">
      <div className="mb-6 rounded-lg bg-white p-6 shadow-md">
        <div>
          <h2 className="mb-4 text-2xl font-semibold">Transaction States</h2>
          <p className="mb-2">
            Understanding the states of a transaction is crucial for effective
            management. In the Trolim Pay API, transactions can be in the
            following states:
          </p>
          <ul className="mb-4 list-inside list-disc">
            <li>
              Pending: The transaction is yet to be processed by the bank or any
              stakeholders involved.
            </li>
            <li>Success: The transaction has been processed successfully.</li>
            <li>
              Failed: The transaction encountered an issue and failed. You can
              retry manually if needed.
            </li>
          </ul>
        </div>

        <div>
          <h2 className="mb-4 text-2xl font-semibold">Authorization</h2>
          <p className="mb-4">
            The Trolim Pay API uses API keys to authenticate requests. You can
            view and manage your API keys in the Trolim Pay Dashboard. You need
            to pass the API Token in the headers with the key as 'Authorization'
            and the value as 'Bearer' (prefix) followed by your token. At the
            time of onboarding, the IP you provide will be whitelisted by us to
            do the transactions.
          </p>
        </div>
      </div>

      <div className="mb-6 rounded-lg bg-white p-6 shadow-md">
        <div className="mb-6">
          <div className="flex border-b">
            {[
              "initiate-payout",
              "fetch-balance",
              "fetch-transaction-status",
              "debit-webhook",
            ].map((tab) => (
              <button
                key={tab}
                onClick={() => handleTabChange(tab)}
                className={`py-2 px-4 ${
                  activeTab === tab
                    ? "border-b-2 border-blue-500 text-blue-500"
                    : "text-gray-500 hover:text-gray-700"
                }`}
              >
                {tab
                  .split("-")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
              </button>
            ))}
          </div>
        </div>

        {activeTab === "initiate-payout" && (
          <div>
            <h2 className="mb-4 text-2xl font-semibold">Initiate Payout API</h2>
            <p className="mb-4">
              Use this API to initiate an amount transfer from your Trolim Pay
              Virtual Account to a beneficiary bank account or UPI VPA. Once a
              payout is initiated and the Trolim Pay system and bank process it,
              you will receive a callback from the Trolim Pay system with the
              status of the Payout.
            </p>
            <h3 className="mb-2 text-xl font-semibold">Request</h3>
            <p className="mb-2">Method: POST</p>
            <p className="mb-2">
              Auth: Please refer to the authorization section
            </p>

            <p className="mb-4">
              API URL:{" "}
              {renderCodeBlock(
                `https://api.Trolim Paypay.in/client/initiatepayout`
              )}
            </p>

            <h4 className="mb-2 text-lg font-semibold">Body</h4>
            {renderCodeBlock(`{
  "amount": 1,
  "account_number": "77220101845051",
  "payment_mode": "UPI",
  "reference_id": "Sathya-test6",
  "transcation_note": "Refund03",
  "beneficiaryName": "Sathya Narayanan",
  "ifsc": "FDRL0007777",
  "upi": "aintworried@axl"
}`)}
            <h3 className="mt-4 mb-2 text-xl font-semibold">Response</h3>
            {renderCodeBlock(`{
  "status": true,
  "statusCode": 200,
  "data": {
    "beneCode": "APIBENEF453",
    "beneName": "name",
    "beneAccNum": "77220101845051",
    "beneIfscCode": "FDRL0007777",
    "beneAcType": 10,
    "transcation_id": "TRAREF000453",
    "amount": 1,
    "payment_remark": "From Peid Piper using Trolim Pay - Refund03",
    "payment_mode": "IMPS",
    "status": "PENDING",
    "message": "Transcation Initiated!"
  },
  "message": "Transcation Processing"
}`)}
          </div>
        )}

        {activeTab === "fetch-balance" && (
          <div>
            <h2 className="mb-4 text-2xl font-semibold">Fetch Balance</h2>
            <p className="mb-4">
              Use this API to get your account balance. Available balance is
              account balance minus sum of all pending payouts.
            </p>
            <h3 className="mb-2 text-xl font-semibold">Request</h3>
            <p className="mb-2">Method: GET</p>
            <p className="mb-2">
              Auth: Please refer to the authorization section
            </p>

            <p className="mb-4">
              API URL:{" "}
              {renderCodeBlock(
                `https://api.Trolim Paypay.in/client/fetchBalance`
              )}
            </p>

            <h3 className="mt-4 mb-2 text-xl font-semibold">Response</h3>
            {renderCodeBlock(`{
  "status": true,
  "statusCode": 200,
  "data": {
    "firstName": "Richard",
    "lastName": "Hendricks",
    "businessName": "business",
    "businessAddress": "business",
    "phone": "9684458448",
    "account_number": "7722771001",
    "ifsc_code": "UTIB0CCH274",
    "VirtualAccount": "",
    "VirtualAccountBank": "Axis",
    "virtualAccountId": "",
    "Balance": 434.90,
    "email": "sathya@email.com",
    "poApiPenny": 1,
    "poAppPenny": 1
  },
  "message": ""
}`)}
          </div>
        )}

        {activeTab === "fetch-transaction-status" && (
          <div>
            <h2 className="mb-4 text-2xl font-semibold">
              Fetch Transaction Status
            </h2>
            <p className="mb-4">
              Use this API to get status of any transaction
            </p>
            <h3 className="mb-2 text-xl font-semibold">Request</h3>
            <p className="mb-2">Method: POST</p>
            <p className="mb-2">
              Auth: Please refer to the authorization section
            </p>

            <p className="mb-4">
              API URL:
              {renderCodeBlock(
                `https://api.Trolim Paypay.in/client/fetchStatus`
              )}{" "}
            </p>

            <h4 className="mb-2 text-lg font-semibold">Body</h4>
            {renderCodeBlock(`{
  "transcation_id":"TRAREF0002211613"
  // OR
  "reference_id": "572D2024030805141585328zMxCZI"
}`)}
            <p className="mb-4">Note: Only one input is accepted.</p>
            <h3 className="mt-4 mb-2 text-xl font-semibold">Response</h3>
            {renderCodeBlock(`{
  "status": true,
  "statusCode": 200,
  "data": {
    "transcation_id": "TRAREF000624",
    "beneficiaryName": "sathya ",
    "account_number": "50100301022325676",
    "ifsc": "HDFC0000683",
    "amount": 1,
    "payment_mode": "",
    "payment_remark": "From Pied Piper using Trolim Pay",
    "status": "SUCCESS",
    "utr": "",
    "holderName": "Sathya ",
    "type": "Debit",
    "settelment_amount": "1.00",
    "closing_balance": "436.90",
    "createdAt": "2022-07-20T08:59:09.744Z",
    "updatedAt": "2022-07-20T08:59:09.744Z"
  },
  "message": ""
}`)}
          </div>
        )}

        {activeTab === "debit-webhook" && (
          <div>
            <h2 className="mb-4 text-2xl font-semibold">
              Debit Webhook (Understanding the callback)
            </h2>
            <p className="mb-4">
              This callback will be triggered when funds are debited from your
              Trolim Pay account.
            </p>
            {renderCodeBlock(`{
  "status": true,
  "statusCode": 200,
  "data": {
    "reference_id": "TNX00177",
    "transcation_id": "TETRAREF02912368",
    "trx_status": "SUCCESS",
    "account_holder_name": "",
    "trx_message": "Success",
    "beneficiaryName": "Sathya",
    "account_number": "aintworried@axl",
    "ifsc": "",
    "amount": 1,
    "payment_mode": "UPI",
    "utr": "325855006923",
    "type": "Debit",
    "isVirtualAccount": false,
    "SubVaId": "",
    "vaName": "",
    "SubVaAccountNumber": "",
    "createdAt": "2023-09-15T13:19:24.702Z",
    "updatedAt": "2023-09-15T13:19:32+00:00"
  },
  "message": ""
}`)}
          </div>
        )}
      </div>
    </div>
  );
}
