import React, { useState, useCallback, useEffect } from "react";
import { updateCallback, fetchVA } from "services/merchant.service";

function CopyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="h-5 w-5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
      />
    </svg>
  );
}

function CopyButton({ text }) {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  }, [text]);

  return (
    <button
      onClick={copyToClipboard}
      className="ml-2 rounded p-1 text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
    >
      {copied ? (
        <span className="text-sm text-green-500">Copied!</span>
      ) : (
        <CopyIcon />
      )}
    </button>
  );
}

export default function Creds() {
  const [credential, setCredential] = useState({});
  const [callbackUrl, setCallbackUrl] = useState(
    "https://example.com/callback"
  );

  // Fetch initial data
  useEffect(() => {
    fetchVA()
      .then((response) => {
        console.log("Fetched data:", response.data);
        setCredential(response.data);
        setCallbackUrl(
          response.data[0]?.debit_callBack || "https://example.com/callback"
        );
      })
      .catch((error) => {
        console.error("Error fetching VA:", error);
      });
  }, []);

  // Handle input change for callback URL
  const handleInputChange = (e) => {
    setCallbackUrl(e.target.value);
  };

  // Update callback URL when the button is clicked
  const handleUpdateCallback = () => {
    if (!callbackUrl) {
      console.error("Callback URL cannot be empty");
      return;
    }
  

    updateCallback({ callback: callbackUrl })
      .then((response) => {
        console.log("Callback URL updated:", response.data);
        alert("Callback Updated")
      })
      .catch((error) => {
        console.error("Error updating callback URL:", error);
      });
  };

  return (
    <div className="container mx-auto p-4">
      {/* API Credentials */}
      <div className="mb-6 rounded-lg bg-white p-6 shadow-md">
        <h2 className="mb-2 text-xl font-semibold">API Credentials</h2>
        <p className="mb-4 text-gray-600">
          Your API key and secret for authentication
        </p>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div className="flex items-center">
            <div>
              <p className="font-semibold">API Key:</p>
              <code className="rounded bg-gray-100 p-1">
                {credential[0]?.apiKey || "Your API Key"}
              </code>
            </div>
            <CopyButton text={credential[0]?.apiKey} />
          </div>
          <div className="flex items-center">
            <div>
              <p className="font-semibold">API Secret:</p>
              <code className="rounded bg-gray-100 p-1">
                {credential[0]?.apiSecret || "Your API Secret"}
              </code>
            </div>
            <CopyButton text={credential[0]?.apiSecret} />
          </div>
        </div>
      </div>

      {/* Callback URL */}
      <div className="mb-6 rounded-lg bg-white p-6 shadow-md">
        <h2 className="mb-2 text-xl font-semibold">Callback URL</h2>
        <p className="mb-4 text-gray-600">
          Update your callback URL for webhook notifications
        </p>
        <div className="flex flex-col items-center space-y-2 sm:flex-row sm:space-y-0 sm:space-x-2">
          <input
            type="url"
            placeholder="Enter callback URL"
            value={callbackUrl}
            onChange={handleInputChange}
            className="w-full flex-grow rounded-md border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 sm:w-auto"
          />
          <button
            onClick={handleUpdateCallback}
            className="w-full rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
}
